import React from "react"
import PropTypes from "prop-types"
import { OutboundLink } from "gatsby-plugin-gtag"

import Button from "../components/button"
import appIcon from "../images/icon.png"
import mockupImage from "../images/mockup.png"
import AppStore from "../svg/app-store.svg"
import { COLORS } from "../styles/constants"

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      height: "100vh",
      flex: 1,
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
    }}
  >
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: 440, position: "relative" }}>
        <img src={mockupImage} alt="a screenshot of thinky on the iPhone" />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          zIndex: -5,
          height: "100vh",
          width: "100vw",
          opacity: 0.5,
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          style={{ verticalAlign: "middle", width: "28px", marginRight: 4 }}
          src={appIcon}
          alt="thinky app icon"
        />
        <h1 style={{ textAlign: "center" }}>Thinky</h1>
      </div>
      <p style={{ textAlign: "center", maxWidth: 440, fontFamily: "Lato" }}>
        Say hello to effortless journaling with Thinky, the beautifully simple
        and secure free journal.
      </p>
      <OutboundLink
        style={{ width: "100%" }}
        href="https://apps.apple.com/gb/app/thinky-mindful-journal/id1484067196"
      >
        <img
          src={AppStore}
          style={{ width: "100%", padding: "0.75rem 5rem" }}
          alt="App Store Logo"
        />
      </OutboundLink>
      <Button>Android Waiting List</Button>
      <OutboundLink
        style={{
          color: COLORS.gray,
          marginTop: 16,
          textDecoration: "none",
          fontSize: 14,
        }}
        href="https://docs.google.com/document/d/1UGU8OBrRaCss4D85rdCKuSvF2AiwKDRwlMEVuwGhhRo/edit?usp=sharing"
      >
        Privacy Policy
      </OutboundLink>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Thinky App`,
}

export default Header
