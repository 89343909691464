import React from "react"

import { COLORS, BORDER_RADIUS } from "../styles/constants"
import "../styles/button.css"

const Button = ({ children }) => (
  <button
    onClick={() =>
      (window.location.href =
        "https://docs.google.com/forms/d/1kfFjuYClGXTxkXsk0y_QgTdCAn92HUIZ4jGCZ_g-8lY")
    }
    style={{
      padding: "0.75rem 5rem",
      color: COLORS.lightWhite,
      fontWeight: 700,
      background: COLORS.lightGreen,
      borderRadius: BORDER_RADIUS,
      borderWidth: 0,
      cursor: "pointer",
    }}
  >
    {children}
  </button>
)

export default Button
